import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  Box,
  Button,
  Form,
  Text,
} from "grommet";
import {
  AnchoredLayout,
  EmailFormField,
  Header,
  Logo,
  Message,
  PasswordFormField,
  Divider,
} from "@/cloverleaf-ui/components";
import { validate } from "@/cloverleaf-ui/utils";
import { getBaseUrl } from "../../../components/utils";
import { AnchorLink } from "@/components/v2/AnchorLink";
import { useLocale } from "@/lib/hooks";

function AuthPage({
  email: initialEmail = undefined,
  error = undefined,
  loading = false,
  onCancel = undefined,
  onSSO = undefined,
  onSubmit = () => undefined,
  organization = undefined,
}) {
  const router = useRouter();

  const { t } = useLocale();

  const { overrideSSO, ...queryParams } = router.query;

  const [email, setEmail] = React.useState(initialEmail || "");
  const [password, setPassword] = React.useState("");

  const emailRef = React.useRef(null);
  const passwordRef = React.useRef(null);

  React.useEffect(() => {
    if (emailRef && emailRef.current) {
      emailRef.current.focus();

      setEmail(emailRef.current.value);
    }
  }, [emailRef]);

  React.useEffect(() => {
    if (passwordRef && passwordRef.current) {
      setPassword(passwordRef.current.value);
    }
  }, [passwordRef]);

  const accountCreateDisabled = Boolean(
    organization?.whitelistEnabled
    && organization?.whitelist?.length === 0,
  );

  const ssoEnabled = Boolean(typeof onSSO === "function" && organization?.ssoUrl);

  const ssoRequired = Boolean(ssoEnabled && organization?.ssoRequired && !overrideSSO);

  const handleOnSubmit = ({ value }) => {
    onSubmit(value);
  };

  const handleOnCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }
  };

  const handleSSO = () => {
    onSSO();
  };

  return (
    <AnchoredLayout>
      <Box align="center" justify="center">
        <Logo src={organization?.bannerLogo} width="100%" />
      </Box>
      <Header
        title={organization?.name
          ? t("title_organization", { organization: organization?.name })
          : t("title")
        }
      />
      <Box gap="medium" fill="horizontal">
        {ssoEnabled && (
          <Box direction="row" gap="medium" justify="center">
            {ssoRequired && onCancel && (
              <Button
                disabled={loading}
                fill="horizontal"
                label={t("button.cancel")?.toUpperCase()}
                onClick={handleOnCancel}
                secondary
              />
            )}
            <Button
              busy={loading}
              disabled={loading}
              fill="horizontal"
              label={t("button.sso_sign_in")?.toUpperCase()}
              onClick={handleSSO}
              primary
            />
          </Box>
        )}
        {!ssoRequired && ssoEnabled && (
          <Divider color="grey400" label={t("or")} />
        )}
        {error && (
          <Message alignSelf="center" compact kind="error">
            {error}
          </Message>
        )}
        {!ssoRequired && (
          <Form method="post" onSubmit={handleOnSubmit}>
            <EmailFormField
              ref={emailRef}
              autoComplete="username"
              autoFocus
              label={t("input_email_field")}
              disabled={Boolean(initialEmail)}
              onChange={e => setEmail(e.target.value)}
              validate={[validate.required]}
              value={email}
            />
            <PasswordFormField
              ref={passwordRef}
              autoComplete="current-password"
              id="current-password"
              label={t("input_password_field")}
              link={{
                href: {
                  pathname: "/account/forgot-password",
                  query: { ...queryParams, email },
                },
                label: t("forgot_password"),
              }}
              onChange={e => setPassword(e.target.value)}
              required
              value={password}
            />
            <Box direction="row" gap="medium" justify="center" margin={{ top: "large" }}>
              {onCancel && !ssoRequired && (
                <Button
                  disabled={loading}
                  fill="horizontal"
                  label={t("button.cancel")}
                  onClick={handleOnCancel}
                  secondary
                />
              )}
              <Button
                busy={loading}
                disabled={loading || !email || !password}
                fill="horizontal"
                label={t("button.sign-in")?.toUpperCase()}
                primary
                type="submit"
              />
            </Box>
          </Form>
        )}
        <Divider color="grey400" />
        <Box
          align="center"
          gap="medium"
        >
          {organization && !accountCreateDisabled && (
            <Box gap="8px">
              <Box
                direction="row"
                gap="xsmall"
                justify="center"
                wrap
              >
                <Text>
                  {t("dont_have_account")}
                </Text>
                {organization?.whitelistEnabled && (
                  <Text>
                    {t("whitelist_condition", { domain: organization?.whitelist[0] })}
                  </Text>
                )}
              </Box>
              <Link
                href={{
                  // If there's an interaction Id we can skip the email verirfication
                  pathname: queryParams.interactionId ? "/account/create/register-account" : "/signup",
                  query: { ...queryParams },
                }}
                passHref
                legacyBehavior
              >
                <Button
                  label={(
                    ssoRequired ? t("create_account_with_sso")?.toUpperCase()
                      : t("create_account")?.toUpperCase()
                  )}
                  secondary
                />
              </Link>
            </Box>
          )}
          {organization && !accountCreateDisabled && !ssoRequired && (
            <Text>{t("or")?.toUpperCase()}</Text>
          )}
          {!ssoRequired &&
            (
              <AnchorLink
                alignSelf="center"
                href={getBaseUrl({ path: "/signin", locale: router.locale })}
                size="medium"
              >
                {t("return_to_sign_in")?.toUpperCase()}
              </AnchorLink>
            )
          }
        </Box>
      </Box>
    </AnchoredLayout>
  );
}

AuthPage.propTypes = {
  email: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSSO: PropTypes.func,
  onSubmit: PropTypes.func,
  organization: PropTypes.shape({
    bannerLogo: PropTypes.string,
    name: PropTypes.string,
    ssoRequired: PropTypes.bool,
    ssoUrl: PropTypes.string,
    subdomain: PropTypes.string,
    whitelist: PropTypes.array,
    whitelistEnabled: PropTypes.bool,
  }),
};

export default AuthPage;
