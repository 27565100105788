import React from "react";
import PropTypes from "prop-types";
import Link from "next/link";
import { Anchor } from "grommet";

function AnchorLinkComponent({
  href = undefined,
  ...props
}, ref) {
  if (!href) {
    return (
      <Anchor {...props} />
    );
  }

  return (
    <Link href={href} passHref ref={ref} legacyBehavior>
      <Anchor {...props} />
    </Link>
  );
}

const AnchorLink = React.forwardRef(AnchorLinkComponent);

AnchorLink.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      query: PropTypes.shape(),
    }),
  ]),
};

export { AnchorLink };
