import { useMutation, gql } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";

const authenticateUserException = ({
  message = "Unknown Error",
  status = "Unknown",
}) => ({
  message,
  status,
});

export const AUTHENTICATE_USER_STATUS = {
  INVALID: "INVALID",
  SUCCESS: "SUCCESS",
  TOO_MANY: "TOO_MANY"
};

export const AUTHENTICATE_USER_QUERY = gql`
  mutation authenticateUser($input: input) {
    authenticateUser(input: $input)
      @rest( type: "Response", path: "/user/signin", method: "POST") {
        data
        message
        status
    }
  }
`;

export const useAuthenticateUser = ({ ...options } = {}) => {
  const [
    authenticateUser,
    { data, loading, error },
  ] = useMutation(AUTHENTICATE_USER_QUERY, { ...options });

  const handleAuthenticateUser = async ({
    email,
    password,
    subdomain,
    timezone,
  }) => {
    try {
      const {
        data: response,
      } = await authenticateUser({
        variables: {
          input: {
            email,
            password,
            subdomain,
            timezone,
          },
        },
      });

      const {
        data: auth,
        message,
        status,
      } = response.authenticateUser;

      return {
        auth,
        message,
        status,
      };
    }
    catch (errorResponse) {
      const { message, status } = errorResponse?.networkError?.result || {};

      Sentry.captureException(errorResponse, {
        extra: {
          arguments: {
            email,
            subdomain,
            timezone,
            password: Boolean(password), // confirm it was there, weirder if it weren't
          },
          message,
          status,
        },
        tags: {
          function: "useAuthenticateUser",
        },
      });

      throw authenticateUserException({ message, status });
    }
  };

  return {
    data,
    error,
    loading,
    authenticateUser: handleAuthenticateUser,
    authenticateUserStatus: AUTHENTICATE_USER_STATUS,
  };
};
