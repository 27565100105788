import React from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import { Grommet } from "grommet";
import { useCloverleafNonce } from "@/cloverleaf-ui/hooks";
import AuthPageUI from "@/cloverleaf-ui/pages/signin/auth";
import { themeStyle } from "@/cloverleaf-ui/theme";
import { b64EncodeUnicode } from "@/cloverleaf-ui/utils";
import { validateRefUrl } from "@/cloverleaf-ui/utils/validate";
import {
  getBaseUrl,
  queryStringify,
  safeReplace,
} from "@/components/utils";
import { getSubdomain } from "@/components/utils/getSubdomain";
import { getBrowserTimezone } from "@/lib";
import {
  alias,
  identify,
  reset,
} from "@/lib/analytics";
import { initializeApollo, addApolloState } from "@/lib/apolloClient";
import { useDeleteTrust, useLocale } from "@/lib/hooks";
import { useAuthenticateUser } from "@/lib/hooks/useAuthenticateUser";
import { serverSideTranslations } from "@/lib/hooks/useLocale"
import { VALIDATE_ORGANIZATION_QUERY, VALIDATE_ORGANIZATION_STATUS } from "@/lib/hooks/useValidateOrganization";
const debug = require("debug")("cloverleaf:auth");

export function AuthPage({
  organization = undefined,
}) {
  const router = useRouter();

  const { t } = useLocale();

  // ignore path string from router.query
  const {
    email: initialEmail,
    ref: redirectUrl,
    token,
    ...queryParams
  } = router.query;

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [, setStoredNonce] = useCloverleafNonce("la_state");

  const { trust: deleteTrust } = useDeleteTrust();

  const { authenticateUser, authenticateUserStatus } = useAuthenticateUser();

  const handleErrorResponse = ({ status, message }) => {
    switch (status) {
      case (authenticateUserStatus.INVALID):
        setErrorMessage(t("invalid_email_or_password"));
        break;
      case (authenticateUserStatus.TOO_MANY):
        setErrorMessage(message)
        break;
      default:
        setErrorMessage(t("unknown_error"));
    }

    return setIsLoading(false);
  };

  const handleSuccess = ({ auth, status }, email) => {
    if (status === authenticateUserStatus.SUCCESS) {
      const { subdomain, userId } = auth || {};

      debug("handleSuccess", subdomain);

      // Identify user in Sentry
      Sentry.setUser({ id: userId })

      Sentry.addBreadcrumb({
        category: "signin-flow",
        data: {
          userId,
          subdomain,
        },
        message: `Authenticated user: ${userId}`,
        level: "info",
      });

      // Identify user for analytics
      reset();
      alias(userId); // alias the anonymousId
      identify(userId, {
        email,
        locale: router?.locale,
      });

      let path = "/my-dashboard";

      if (redirectUrl) {
        // Validate the redirect URL
        const refUrlError = validateRefUrl(redirectUrl);

        if (refUrlError) {
          const message = `Invalid redirect URL: [${redirectUrl}]`;

          // Log error
          console.log(`auth.js: ${message}`, refUrlError);
        }
        else {
          // check if redirectUrl was encoded
          if (decodeURI(path) !== decodeURIComponent(path)) {
            path = decodeURIComponent(redirectUrl);
          }
          else {
            path = redirectUrl;
          }
        }
      }

      /**
       * If there is a redirectUrl, we likely have a flow we want to target
       * and don't want to interrupt it by redirecting to /notifications
       */
      const queryString = queryStringify({ chkNR: !redirectUrl, ...queryParams });

      const url = getBaseUrl({
        subdomain: auth?.subdomain,
        path,
        query: queryString,
        locale: router.locale,
      });

      // Hard route specifically for account finalization session
      window.location.href = url;

      return new Promise(() => undefined);
    }

    return handleErrorResponse(status);
  };

  const handleOnSubmit = (credentials) => {
    setIsLoading(true);
    setErrorMessage("");

    return authenticateUser({
      email: credentials?.email,
      password: b64EncodeUnicode(credentials?.password),
      subdomain: organization?.subdomain,
      timezone: getBrowserTimezone(), // get the browser timezone
    })
      .then((response) => handleSuccess(response, credentials?.email))
      .catch(handleErrorResponse);
  };

  const handleOnCancel = queryParams?.code ? () => {
    // Presumably a code from link-account flow, so delete associated trust
    setIsLoading(true);
    setStoredNonce(null);

    deleteTrust({ code: queryParams?.code });

    return router.back();
  } : undefined;

  const handleSSO = () => {
    setIsLoading(true);

    const ssoRedirectUrl = getBaseUrl({
      subdomain: organization?.subdomain,
      path: "/api/auth/sso/login",
      query: {
        subdomain: organization?.subdomain,
        team_link: router?.query?.team_link,
        org_link: router?.query?.org_link,
        token: token,
      },
      useLocalAPIPort: true,
      locale: router.locale,
    });

    // Open the SSO endpoint to login - if successful we should be redirected
    return safeReplace(router, ssoRedirectUrl)();
  };

  return (
    <Grommet theme={themeStyle}>
      <AuthPageUI
        email={initialEmail}
        error={errorMessage}
        loading={isLoading}
        onCancel={handleOnCancel}
        onSSO={handleSSO}
        onSubmit={handleOnSubmit}
        organization={organization}
      />
    </Grommet>
  );
}

export async function getServerSideProps(ctx) {
  const translations = await serverSideTranslations(ctx.locale, [
    "common",
  ]);

  // Pull the subdomain out of the context, if we have one
  const subdomain = getSubdomain(ctx);

  if (!subdomain || subdomain === "www-local" || subdomain === "app") {
    return {
      props: {
        ...translations,
      },
    };
  }

  const apolloClient = initializeApollo({ ctx });

  if (apolloClient) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: VALIDATE_ORGANIZATION_QUERY,
        variables: { input: { subdomain } },
      });

      if (data?.validateOrganization?.status === VALIDATE_ORGANIZATION_STATUS.SUCCESS) {
        return addApolloState(apolloClient, {
          props: {
            organization: {
              ...data?.validateOrganization?.organization,
            },
            ...translations,
          },
        });
      }
    }
    catch (error) {
      Sentry.captureMessage(error, {
        category: "signin-flow",
        message: `invalid subdomain: ${subdomain}`,
        level: "error",
      });
    }
  }

  const destination = getBaseUrl({
    path: "/signin",
    query: ctx.query,
    locale: ctx.locale,
  });

  return {
    redirect: {
      destination,
      permanent: false,
    },
  };
}

AuthPage.propTypes = {
  organization: PropTypes.shape({
    bannerLogo: PropTypes.string,
    logo: PropTypes.string,
    name: PropTypes.string,
    ssoUrl: PropTypes.string,
    subdomain: PropTypes.string,
    whitelist: PropTypes.array,
    whitelistEnabled: PropTypes.bool,
  }),
};

export default AuthPage;
